import { message } from "antd";
import React, { useState } from "react";
import { ArrowDownIcon } from "../components/common/ArrowDown.Icon";
import { ArrowUpIcon } from "../components/common/ArrowUp.Icon";
import { DeleteIcon } from "../components/common/Delete.Icon";
import { EBButtonV2 } from "../components/common/EBButtonV2";
import { ArrayHelper } from "../helpers/Array.Helper";
import { ArrowIcon } from "../icon/Arrow.Icon";
import { Target } from "../model/target.model";
import { ButtonText } from "../model/views/Button.props";
import { UserBasicDetails } from "../user/User";
import { ContentInputSidebar } from "./Content.Input.Sidebar";
import "./Content.Input.css";
import { Content, ContentItem, ContentItemType } from "./Content.model";
import {
  ContentItemTypeFactory,
  IContentItemHandler,
} from "./ContentItemType.Handlers";
import { ContentItemInput } from "./items/input/ContentItemInput";
import { ContentWidgetPicker } from "./Content.Widget.Picker";

interface ContentInputProps {
  content: Content;
  user: UserBasicDetails;
  topNode?: React.ReactNode;
  target: Target;
  postedBy: UserBasicDetails;
  onTriggerSave: () => Promise<void>;
  onChange: () => Promise<void>;
}

export function ContentInput(props: ContentInputProps) {
  let content: Content = props.content;
  let [reRenderCount, setRenderCount] = useState<number>(0);

  function reRender() {
    setRenderCount(reRenderCount + 1);
  }

  function getNextItemOrder() {
    if (!content || !content.items || content.items.length == 0) {
      return 0;
    }
    let maxOrder = content.items[0].displayOrder;
    for (let i = 1; i < content.items.length; i++) {
      let item = content.items[i];
      if (maxOrder < item.displayOrder) {
        maxOrder = item.displayOrder;
      }
    }
    return maxOrder + 1;
  }

  function addNewContentItem(itemTypeHandler: IContentItemHandler) {
    let newItem: ContentItem = {
      type: {
        typeEnum: itemTypeHandler.typeEnum,
      } as ContentItemType,
      data: itemTypeHandler.getInitialContentData(),
      displayOrder: getNextItemOrder(),
    };
    if (!content.items) {
      content.items = [];
    }
    content.items.push(newItem);
    reRender();
    props.onChange();
  }

  function _remove(index: number) {
    ArrayHelper.removeByIndex(content.items, index);
    reRender();
    props.onChange();
  }

  function remove(index: number) {
    let item = content.items[index];
    let handler = ContentItemTypeFactory.getContentItemHandler(
      item.type.typeEnum
    );
    if (handler.canDelete) {
      handler.canDelete(item.data).then((deleteCheckResponse) => {
        if (deleteCheckResponse.canDelete) {
          _remove(index);
        } else {
          message.error(deleteCheckResponse.reasonForDisallowingDelete);
        }
      });
    } else {
      _remove(index);
    }
  }

  function swapItemPositions(index1: number, index2: number) {
    let item1 = content.items[index1];
    let item1Order = item1.displayOrder;
    let item2 = content.items[index2];
    let item2Order = item2.displayOrder;

    item1.displayOrder = item2Order;
    item2.displayOrder = item1Order;

    content.items[index1] = item2;
    content.items[index2] = item1;

    reRender();
    props.onChange();
  }

  function moveUp(index: number) {
    if (!content || !content.items || content.items.length <= 1) {
      return;
    }
    if (index == 0) {
      return;
    }
    swapItemPositions(index, index - 1);
  }

  function moveDown(index: number) {
    if (!content || !content.items || content.items.length <= 1) {
      return;
    }
    if (index == content.items.length - 1) {
      return;
    }
    swapItemPositions(index, index + 1);
  }

  return (
    <span className="ContentInput">
      <div className="row m-0 h-100">
        <div className="col p-4">
          {props.topNode}
          {content &&
            content.items.map((item, index) => (
              <div key={index} className="content-input-item">
                <div>
                  <ContentItemInput
                    item={item}
                    user={props.user}
                    target={props.target}
                    postedBy={props.postedBy}
                    onTriggerSave={props.onTriggerSave}
                    onChange={props.onChange}
                  />
                  <div
                    className="content-input-item-menu"
                    style={{ right: -31, left: "auto", top: 0 }}
                  >
                    <div
                      className="btn-group-vertical text-left border rounded-1"
                      role="group"
                      aria-label="Vertical button group"
                    >
                      <ContentInputItemButtons
                        buttonsData={[
                          {
                            content: { normal: <ArrowUpIcon /> },
                            onClick: () => {
                              moveUp(index);
                            },
                          },
                          {
                            content: { normal: <ArrowDownIcon /> },
                            onClick: () => {
                              moveDown(index);
                            },
                          },
                          {
                            content: {
                              normal: (
                                <span className="text-danger">
                                  <DeleteIcon />
                                </span>
                              ),
                            },
                            onClick: () => {
                              remove(index);
                            },
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <div
            className="alert alert-light mt-4 w-100 px-0 "
            role="alert"
            style={{ borderStyle: "dashed" }}
          >
            <i className="fas fa-info-circle "></i> Use below button to add
            blocks of elements.
          </div>

          <div>
            <ContentWidgetPicker
              user={props.user}
              contentItemTypeHandlers={ContentItemTypeFactory.getAllContentItemHandlers()}
              onSelect={addNewContentItem}
            />
          </div>
        </div>
        {/* <ContentInputSidebarWrapper
          user={props.user}
          onSelect={addNewContentItem}
        /> */}
      </div>
    </span>
  );
}

interface ContentInputSidebarWrapperProps {
  user: UserBasicDetails;
  onSelect: (itemTypeHandler: IContentItemHandler) => void;
}

function ContentInputSidebarWrapper(props: ContentInputSidebarWrapperProps) {
  let [sidebarVisible, setSidebarVisible] = useState(true);
  function toggleSidebarVisibility() {
    setSidebarVisible(!sidebarVisible);
  }

  return (
    <div
      className="col-auto border-left px-0"
      style={{
        width: sidebarVisible ? "300px" : "18px",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: -15,
          top: 0,
          zIndex: 1000,
        }}
      >
        {sidebarVisible && (
          <EBButtonV2
            className="btn-sm btn-light rounded-circle border  border-1"
            content={{ normal: <ArrowIcon dir={"right"} /> }}
            onClick={toggleSidebarVisibility}
          />
        )}

        {!sidebarVisible && (
          <EBButtonV2
            className="btn-sm btn-light rounded-circle border border-1"
            content={{ normal: <ArrowIcon dir={"left"} /> }}
            onClick={toggleSidebarVisibility}
          />
        )}
      </div>
      {sidebarVisible && (
        <ContentInputSidebar
          user={props.user}
          contentItemTypeHandlers={ContentItemTypeFactory.getAllContentItemHandlers()}
          onSelect={props.onSelect}
        />
      )}
    </div>
  );
}

interface ContentInputItemButtonData {
  content: ButtonText;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

interface ContentInputItemButtonsProps {
  buttonsData: ContentInputItemButtonData[];
}

function ContentInputItemButtons(props: ContentInputItemButtonsProps) {
  return (
    <>
      {props.buttonsData.map((buttonData, index) => (
        <EBButtonV2
          className="btn btn-light btn-sm"
          content={buttonData.content}
          onClick={buttonData.onClick}
          key={index}
        />
      ))}
    </>
  );
}
